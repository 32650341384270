@media screen and (min-width:320px) and (max-width:450px) {
    .contact-buttons {
      display:contents !important;
    }
    .company-name {
      font-weight: 600;
      font-size: 20px !important;
      display: flex;
      margin-top: 50px !important;
    }
  }
  
  
  .company-details a {
    text-decoration: none;
    color: black;
  }
  
  .nthindex-col img{
    height: -webkit-fill-available;
    object-fit: contain;
  }

  .neis img{
    object-fit: contain !important; 
    height: -webkit-fill-available;

  }
  
  .company-name {
    font-weight: 600;
    font-size: 30px;
    display: flex;
  }
  
  .company-category,
  .company-address,
  .company-email,
  .company-web,
  .company-call {
    display: flex;
    align-items: start;
  }
  .company-category span {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
  }
  
  .company-address span {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
  }
  
  .company-frame {
    margin-top: 19px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
  
  .company-description {
    padding: 51px 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
  
  .company-service-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    color: #000000;
    padding-bottom: 15px;
  }
  
  .company-social {
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.25);
    border-radius: 26px;
    display: inline-flex;
    flex-direction: row;
    gap: 33px;
    padding: 12px 23px;
  }
  
  .details-row {
    margin-top: 100px;
  }
  
  .bg3 {
    background-image: url("../../../../assets/images/map_illustration.svg");
    background-repeat: no-repeat;
    background-position-y: top;
  }
  
  .contact-buttons {
    display: flex;
  }
  
  .contact-wu {
    display: flex;
    height: 36px;
    background-color: #39ce48 !important;
    border-color: #39ce48 !important;
    padding: 4px 25px !important;
  }
  
  .contact-call {
    background-color: #2685ff !important;
    border: none !important;
    display: flex;
    align-items: center;
  }
  
  .contact-map {
    background-color: #ff9900 !important;
    border: none;
    display: flex;
  }
  
  .company-social-div {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  
  .service-list {
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }
  