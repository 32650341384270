@media screen and (min-width: 320px) and (max-width: 450px) {
  .restaurant-head {
    font-size: 2.5rem !important;
    line-height: 45px !important;
  }
}

.restaurant-head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 66px;
  color: #000000;
  margin-top: 125px;
  margin-bottom: 65px;
}

.res-div {
  position: relative;
  color: white;
}

.res-div p {
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.28rem;
  line-height: 27px;
  color: #ffffff;
}

.more-mallu {
  background-color: transparent !important;
  color: black;
  border: none;
}

.more-mallu:hover {
  background-color: transparent !important;
  color: black;
  border: none;
}
