body{
    font-size: 14px;
}

@media screen and (max-width:450px) {
    .bg{
        background-position-y: top !important;
        background-size: cover !important;
    }
}

.bg {
    background-image: url("../../assets/images/map_illustration.svg"), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.414141) 16.15%, rgba(255, 255, 255, 0) 70.83%, #FFFFFF 100%);;
    background-repeat: no-repeat;
    background-position-y: center;
  }