@media screen and (min-width: 968px) {
  .banner {
    border-radius: 50px;
  }

  .banner {
    border-radius: 20px;
  }

  .bannerContainer {
    position: relative;
    background-image: url("../../assets/images/map_illustration.svg");
    background-repeat: no-repeat;
  }

  .tagline > span {
    color: #39ce48;
  }

  .tagline > span:nth-child(1) {
    color: #faab33;
  }

  .searchBar {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    box-shadow: 0px 0px 13px 4px rgba(0, 0, 0, 0.25);
    border-radius: 49px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 120px;
    gap: 29px;
  }

  .Dropdown-control {
    border: none !important;
    width: 232px;
  }

  .Dropdown-control:hover {
    box-shadow: none !important;
  }

  .partition {
    width: 1px;
    height: 36px;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 1.39%,
      rgba(53, 53, 53, 0.31) 51.52%,
      rgba(0, 0, 0, 0) 98.61%
    );
  }

  
  .search-btn {
    background-color: #39ce48 !important;
    border-color: #39ce48 !important;
    padding: 4px 25px !important;
  }

  .mobile-searchbar {
    background: #ffffff;
    box-shadow: 0px 0px 13px 4px rgba(0, 0, 0, 0.25);
    border-radius: 49px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 120px;
    gap: 29px;
  }

  .tagline {
    background: rgba(0, 0, 0, 0.52);
    color: white;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    padding: 30px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 320px) and (max-width: 450px) {
  .bannerContainer {
    position: relative;
    background-image: url("../../assets/images/map_illustration.svg");
    background-repeat: no-repeat;
  }

  .tagline > span {
    color: #39ce48;
  }

  .tagline > span:nth-child(1) {
    color: #faab33;
  }
  .column {
    display: flex !important;
    justify-content: center !important;
  }
  .premium-head {
    font-size: 2.5rem !important;
    line-height: 45px !important;
  }
  .tagline {
    background: rgba(0, 0, 0, 0.52);
    color: white;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}


