@media screen and (min-width: 320px) and (max-width: 450px) {
  .education-heading {
    font-size: 2.5rem !important;
    line-height: 45px !important;
  }
}

.education-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 66px;
  color: #000000;
  margin-top: 125px;
  margin-bottom: 65px;
}

.education-div {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  width: auto;
  min-height: 335px;
}

.education-div img {
  width: -webkit-fill-available !important;
  max-height: 226px;
}

.company-names {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.28rem;
  line-height: 27px;
  color: #000000;
  text-align: justify;
}

.company-service {
  padding-top: 10px;
  padding-bottom: 15px;
}

.service {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  text-align: justify;
}
