.footer {
  background-color: #1a2711;
  color: white;
  padding: 60px 0;
  margin-top: 60px;
}

.footer-social {
  gap: 33px;
  display: flex;
  justify-content: center;
}

.footer-header {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 30px;
  position: relative;
}

.footer-header::after {
  content: "";
  position: absolute;
  width: 10%;
  height: 3px;
  background-color: #46503d;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.footer-item {
  color: white;
  text-decoration: none;
  font-size: 14px;
  line-height: 21px;
}

.footer-item a:hover{
  color: white !important;
  text-decoration: none;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

@media screen and (min-width: 968px) {
  .footer {
    padding: 120px;
  }
}
