@media screen and (max-width: 450px) {
  .comingsoon-bg {
    background-size: cover !important;
  }

  .mobile-col img {
    height: 300px !important;
    position: relative !important;
  }
  .mobile-col {
    display: initial !important;
  }
  .comingsoon-row {
    padding-top: 75px !important;
  }
  .sp1 {
    font-size: 2.5rem !important;
  }
  .sp2 {
    font-size: 2.5rem !important;
  }
  .ios {
    font-size: 1.5rem !important;
  }
  .soon-col {
    text-align: center !important;
    margin-bottom: 150px;
  }
}

.comingsoon-bg {
  background-image: url("../../assets/comingsoon/comingsoonbg.svg");
  background-repeat: no-repeat;
  position: relative;
  margin-top: 200px;
}

.comingsoon-row {
  padding-top: 150px;
}

.mobile-col {
  display: flex;
}

.mobile-col img {
  height: 560px;
  position: absolute;
}

.soon-col {
  text-align: left;
  margin-bottom: 150px;
}

.soon-col p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 28px;
  color: #ffffff;
  padding-top: 40px;
}

.sp1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 69px;
  color: #39ce48;
}

.sp2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 69px;
  color: white;
}

.ios {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.85rem;
  line-height: 39px;
  color: #ffffff;
  padding-top: 40px;
}

.forms-id {
  background-color: white;
  border-radius: 12px;
  border: none;
  padding: 5px 10px;
}

.forms-id::placeholder {
  color: black;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 32px;
}

.comingsoon-button {
  background-color: #39ce48;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
  height: 32px;
}

.comingsoon-button:hover {
  background-color: #39ce48;
  color: black;
}

.contact {
  display: flex;
  align-items: baseline;
}

input[type=email]:focus {
  border: rgb(240, 5, 5) !important;
}