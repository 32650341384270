.nav-link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1.14rem;
  line-height: 19px;
  color: #000000;
}

.login-btn {
  padding: 4px 25px !important;
}
