@media screen and (min-width: 320px) and (max-width: 450px) {
  .meeting-head {
    font-size: 2.5rem !important;
    line-height: 45px !important;
  }
  .meetinghall-img {
    padding-left: 20px !important;
    width: 100%;
  }
  .react-multi-carousel-list {
    overflow: scroll !important;
    scroll-snap-type: x mandatory !important;
    scroll-snap-align: start !important;
  }
  .react-multiple-carousel__arrow--right {
    display: none !important;
  }
  ::-webkit-scrollbar {
    background: transparent !important;
    width: 0;
  }
}

.meeting-head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 66px;
  color: #000000;
  margin-top: 175px;
  margin-bottom: 65px;
}

.meetinghall-img {
  padding-right: 20px;
  width: 100%;
  object-fit: none;
}

.cr {
  position: relative;
  color: white;
}

.cr p {
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.28rem;
  line-height: 27px;
  color: #ffffff;
}
