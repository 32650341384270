@media screen and (max-width: 450px) {
  .premium-img {
    padding-left: 20px !important;
  }
  .react-multi-carousel-item--active {
    width: 319px !important;
  }
  .react-multi-carousel-list {
    overflow: scroll !important;
    scroll-snap-type: x mandatory !important;
    scroll-snap-align: start !important;
  }
  .react-multiple-carousel__arrow--right {
    display: none !important;
  }
  ::-webkit-scrollbar {
    background: transparent !important;
    width: 0;
  }
}

.premium-head {
  font-family: "Poppins";
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 65px;
}

.react-multi-carousel-item--active {
  width: 320px !important;
}

.bqyzQp {
  transition: 0.8s ease-in !important;
}

.premium-img {
  padding-right: 20px;
  width: 100%;
  height: 100%;
  object-fit: none;
}

.premium-store {
  position: relative;
  color: white;
}

.premium-store p {
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.28rem;
  line-height: 27px;
  color: #ffffff;
}
