@media screen and (min-width: 320px) and (max-width: 450px) {
    .restaurant-head {
      font-size: 2.5rem !important;
      line-height: 45px !important;
    }
  }
  
  .bg2 {
    background-image: url("../../assets/images/map_illustration.svg");
    background-repeat: no-repeat;
    background-position-y: center;
  }

  .restaurant-head {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 3.14rem;
    line-height: 66px;
    color: #000000;
    margin-top: 125px;
    margin-bottom: 65px;
  }
  
  .res-div {
    position: relative;
    color: white;
  }
  
  .res-div p {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.28rem;
    line-height: 27px;
    color: #ffffff;
  }
  
 