@media screen and (max-width:450px) {
  .category-col {
    width: -webkit-fill-available !important;
  }
}


.categories .span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 21px;
  color: #000000;
}

.categories {
  margin-top: 50px;
}

.category-col {
  /* width: 255px; */
  height: 95px;
  background: #ffffff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
}

.category-data {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.column {
  margin-bottom: 25px;
}
